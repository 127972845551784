import React, { FormEvent, useState } from 'react';
import './assets/scss/bootstrap-theme.scss'
import { Button, Container, FloatingLabel, Form } from "react-bootstrap";
import styles from './App.module.scss'
import { RequestState } from "./models/RequestState";
import Status from "./status/Status";
import { Configuration, ErrorResponse, TrackingRejectionApi } from "./generated-src/_api";
import DSGVOText from "./DSGVOText";

declare const window: any;

function App() {
    const [email, setEmail] = useState('');
    const [requestState, setRequestState] = useState<RequestState>(RequestState.INITIAL);
    const [errorResponse, setErrorResponse] = useState<ErrorResponse | null>(null);
    const params = new URLSearchParams(window.location.search);
    const hash = params.get('hash');
    const event = params.get('event');
    const parameterMissing = !hash || !event;
    return (
        <>
            <Container fluid className="w-75">
                <DSGVOText></DSGVOText>
                {parameterMissing ||
                 <div className=" m-auto mt-4 mb-3">
                     <Form onSubmit={sendToBackend} className="d-flex fs-5">
                         <div className={styles.roundBorder + " mx-auto flex-column d-flex mb-5"}>
                             <FloatingLabel controlId="floatingInput" label="E-Mail-Adresse"
                                            className="mb-3 fs-6">
                                 <Form.Control
                                     type="email"
                                     value={email}
                                     onChange={event => setEmail(event.target.value)}
                                     className="my-2"
                                     required
                                 />
                             </FloatingLabel>
                             <Form.Text id="emailBlock" muted className="text-center text-lg-start my-2">
                                 Bitte geben sie eine valide E-Mail-Adresse ein, um sich vom Tracking abzumelden.
                             </Form.Text>
                             <Button disabled={!email || parameterMissing || requestState === RequestState.RUNNING}
                                     id="submit" variant="primary"
                                     type="submit"
                                     className="col-lg-6 col-md-6 col-sm-8 my-2 mb-3">
                                 {requestState === RequestState.RUNNING ?
                                  <span className="spinner-border spinner-border-sm me-2 text-light" role="status"
                                        aria-hidden="true"></span> : null}
                                 Abmelden
                             </Button>
                             <Status requestState={requestState} errorResponse={errorResponse}></Status>
                         </div>
                     </Form>
                 </div>
                }
            </Container>

            <div className={styles.footer + " mt-5"}>
                <span><a href="https://www.universum-group.de/datenschutz/" target="_blank"
                         rel="noreferrer">Datenschutz</a></span>
                <span><a href="https://www.universum-group.de/impressum/" target="_blank" rel="noreferrer">Impressum</a></span>
                <span>© Universum inkasso</span>
            </div>
        </>
    );

    function sendToBackend(formEvent: FormEvent) {
        formEvent.preventDefault();
        setErrorResponse(null);
        setRequestState(RequestState.RUNNING);

        const api = new TrackingRejectionApi(
            new Configuration({ basePath: window.location.origin}));

        const data = {
            verificationData: {
                email: email,
                hash: hash!,
                event: event!
            }
        };
        api.processTrackingRejection(data)
           .catch(response => {
               response.response.json().then((errorResponse: ErrorResponse) => {
                   setErrorResponse(errorResponse);
               }).catch(() => setErrorResponse({
                   status: response.status,
                   error: "Fehler",
                   message: "Ein Unbekannter Fehler ist beim Absenden der Anfrage aufgetreten"
               }))
           }).finally(() => setRequestState(RequestState.FINISHED));
    }

}


export default App;
