import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import styles from "./App.module.scss";
import { Navbar } from "react-bootstrap";
import UiLogo from "./assets/ui-logo.svg";

export default function Router() {
    return (
        <>
        <Navbar className={styles.navbar}>
            <Navbar.Brand>
                <a href="https://www.universum-group.de/">
                <img
                    alt="Not available"
                    src={UiLogo}
                    className={styles.image + " d-inline-block align-top"}
                />
                </a>
            </Navbar.Brand>
            <h3 className="m-3 text-center">Vom Email Tracking abmelden</h3>
        </Navbar>
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<App></App>}></Route>
            </Routes>
        </BrowserRouter>
        </>
    )
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Router></Router>
    </React.StrictMode>
);
