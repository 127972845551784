/* tslint:disable */
/* eslint-disable */
/**
 * Outbound Engine Inxmail Tracker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerificationData
 */
export interface VerificationData {
    /**
     * 
     * @type {string}
     * @memberof VerificationData
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationData
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationData
     */
    event?: string;
}

/**
 * Check if a given object implements the VerificationData interface.
 */
export function instanceOfVerificationData(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('hash' in value)) return false;
    return true;
}

export function VerificationDataFromJSON(json: any): VerificationData {
    return VerificationDataFromJSONTyped(json, false);
}

export function VerificationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerificationData {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'hash': json['hash'],
        'event': json['event'] == null ? undefined : json['event'],
    };
}

export function VerificationDataToJSON(value?: VerificationData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'hash': value['hash'],
        'event': value['event'],
    };
}

