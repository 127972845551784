/* tslint:disable */
/* eslint-disable */
/**
 * Outbound Engine Inxmail Tracker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  VerificationData,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    VerificationDataFromJSON,
    VerificationDataToJSON,
} from '../models/index';

export interface ProcessTrackingRejectionRequest {
    verificationData: VerificationData;
}

/**
 * 
 */
export class TrackingRejectionApi extends runtime.BaseAPI {

    /**
     * processes the request of a debtor to stop being tracked
     */
    async processTrackingRejectionRaw(requestParameters: ProcessTrackingRejectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['verificationData'] == null) {
            throw new runtime.RequiredError(
                'verificationData',
                'Required parameter "verificationData" was null or undefined when calling processTrackingRejection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tp/trackingRejection`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VerificationDataToJSON(requestParameters['verificationData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * processes the request of a debtor to stop being tracked
     */
    async processTrackingRejection(requestParameters: ProcessTrackingRejectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.processTrackingRejectionRaw(requestParameters, initOverrides);
    }

}
