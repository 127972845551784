import {RequestState} from "../models/RequestState";
import {Alert} from "react-bootstrap";
import React from "react";
import {StatusProperties} from "../models/StatusProperties";
import {ErrorResponse} from "../generated-src/_api";

function Status(props: StatusProperties) {
    return showStatus(props.requestState, props.errorResponse)
}

function showStatus(requestState: RequestState, errorResponse: ErrorResponse | null) {
    if (requestState === RequestState.FINISHED) {
        return <Alert variant={errorResponse ? "danger" : "success"} className="my-3 fs-6">
            <p>{errorResponse ? errorResponse.message : 'Sie wurden erfolgreich vom Tracking abgemeldet.'}</p>
        </Alert>
    } else {
        return null;
    }
}

export default Status;
